<template>
  <div>
    <div class="bg-main-image">
      <!-- Navbar -->
      <section id="navbar" class="navbar-dqa pt-2 pb-2 pl-4 pr-4" style="z-index: 10000;">
        <div class="container-dqa d-flex justify-space-between">
          <a href="/">
            <img src="../assets/images/logobrand.png" width="100" height="45">
          </a>
          <div class="hide-on-mobile align-self-center">
            <ul class="d-flex ga-5">
              <li><a href="#about" class="fs-20 text--white cursor-pointer" @click.prevent="showSection('about')">Tentang Kami</a></li>
              <li><a href="#service" class="fs-20 text--white cursor-pointer" @click.prevent="showSection('service')" >Layanan</a></li>
              <li><a href="#gallery" class="fs-20 text--white cursor-pointer" @click.prevent="showSection('gallery')" >Galeri</a></li>
              <li><a href="#form" class="fs-20 text--white cursor-pointer"  @click.prevent="showSection('form')" >Formulir</a></li>
              <li><a href="#contact" class="fs-20 text--white cursor-pointer" @click.prevent="showSection('contact')">Kontak</a></li>
            </ul>
          </div>
          <div class="show-on-mobile align-self-center">
            <div class="border-2px-white br-5px pr-1 pl-1 cursor-pointer" id="toggleBar">
              <i class="fa-solid fa-bars text--white"></i>
            </div>
          </div>
        </div>
      </section>
      <section id="navbar-mobile" class="show-on-mobile position-fixed w-100 bg-maincolor transition-06s border-t-md" style="z-index: 10000; top:64px; display:none;">
        <ul class="d-flex-col ga-5">
          <li class="ml-4 pt-2"><a href="#about" class="fs-20 text--white cursor-pointer" @click.prevent="showSection('about')" >Tentang Kami</a></li>
          <li class="ml-4"><a href="#service" class="fs-20 text--white cursor-pointer" @click.prevent="showSection('service')">Layanan</a></li>
          <li class="ml-4"><a href="#gallery" class="fs-20 text--white cursor-pointer" @click.prevent="showSection('gallery')">Galeri</a></li>
          <li class="ml-4"><a href="#form" class="fs-20 text--white cursor-pointer" @click.prevent="showSection('form')" >Formulir</a></li>
          <li class="ml-4 pb-2"><a href="#contact" class="fs-20 text--white cursor-pointer" @click.prevent="showSection('contact')">Kontak</a></li>
          
        </ul>
      </section>
      <!-- Home -->
      <section id="home" class="pt-16 pl-2 pr-2" style="padding-bottom: 69px;">
        <div class="container-dqa d-flex justify-center" style="height: 80vh;">
          <div class="align-content-center">
            <div>
              <span class="text--maincolor ff-Custom-1 fs-80">Japan Edutravel</span>
            </div>
            <div class="d-flex justify-center">
              <span class="bg-maincolor pa-1 pl-16 pr-16 fs-20 br-20px fw-600">by Travel with Amarta</span>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex justify-center">
          <span class="border-2px-white pa-3 br-20px bg-main-color cursor-pointer selengkapnya">LIHAT SELENGKAPNYA</span>
        </div> -->
      </section>
    </div>

    <!-- About -->
    <section id="about"  v-show="visibleSections.includes('about')"  class="pt-16 pl-2 pr-2">
      <div class="container-dqa d-flex mt-16 pb-16">
        <div class="grid-box-2 ">
          <v-col class="d-flex-col justify-center">
            <div class="pb-2">
              <span class="fs-48 ff-Custom-1">Tentang Kami</span>
            </div>
            <div class="text-align-justify">
              <span class="fs-16">CV Travel With Amarta (TWA) merupakan perusahaan konsultan yang menyediakan jasa perjalanan ke Jepang. Kami telah beroperasi sejak 2016 (melalui CV Prima Interindo). Dengan pembaruan, kami hadir untuk memberikan pengalaman dan pelayanan terbaik melalui berbagai pilihan program yang dapat disesuaikan dengan kebutuhan klien. Kami juga dapat menyediakan halal edutravel bagi instansi yang membutuhkan. </span><a href="https://drive.google.com/drive/folders/1jyQ9uqq6zfB5Xb4ZTqHEqQisVM3eXIHs" class="text-red-darken-1 fw-600" target="_blank">Cek legalitas kami disini.</a>
            </div>
          </v-col>
          <v-col class="text-align-center">
           <div class="grid-box-2 about-img">
            <div class="image-container">
              <img class="img-fluid" src="../assets/images/performancekebudayaan.png" alt="Performance Kebudayaan">
              <span class="bg-red-darken-4 br-20px pa-1">Performance Kebudayaan</span>
            </div>
            <div class="image-container">
              <img class="img-fluid" src="../assets/images/museumvisit.png" alt="Museum Visit">
              <span class="bg-red-darken-4 br-20px pa-1">Museum Visit</span>
            </div>
            <div class="image-container">
              <img class="img-fluid" src="../assets/images/companyvisit.png" alt="Company Visit">
              <span class="bg-red-darken-4 br-20px pa-1">Company Visit</span>
            </div>
            <div class="image-container">
              <img class="img-fluid" src="../assets/images/kunjunganpersahabatan.png" alt="Kunjungan Persahabatan">
              <span class="bg-red-darken-4 br-20px pa-1">Kunjungan Persahabatan</span>
            </div>
           </div>
          </v-col>
        </div>
      </div>
      <div class="container-dqa d-flex mt-16 pb-16">
        <div class="grid-box-2 ">
          <v-col class="text-align-center">
            <img class="img-fluid image-shadow" src="../assets/images/edutravel.png" height="800" width="500">
          </v-col>
          <v-col class="d-flex-col justify-center">
            <div class="pb-2">
              <span class="fs-48 ff-Custom-1">Tentang Edutravel</span>
            </div>
            <div class="text-align-justify">
              <span class="fs-16">Edutravel adalah metode pembelajaran yang menggabungkan konsep belajar formal dan non formal. Program ini menyediakan sarana eksplorasi aktif bagi peserta dengan tujuan agar mereka dapat mengaplikasikan ilmu secara langsung di dunia sekitar. Jepang menjadi fokus utama destinasi kami karena menawarkan banyak situs wisata edukasi menarik untuk dikunjungi. Selain itu Jepang diakui secara internasional sebagai negara yang memiliki sistem pendidikan percontohan di Asia.</span>
            </div>
          </v-col>
        </div>
      </div>
    </section>

    <!--Service  -->
    <section id="service" v-show="visibleSections.includes('service')"  class="pt-16 " >
      <div class="container-dqa d-flex-col">
        <div class="align-self-center">
          <span class="fs-48 ff-Custom-1">Layanan</span>
        </div>

        <div class="grid-box-service ga-16 pt-5 pl-3 pr-3" >
          <div class="d-flex-col">
              <div class="d-flex justify-center mb-2">
                <div class="border-point-red"></div>
                <h2 class="fs-30 ff-Custom-1">Perencanaan Program</h2>
              </div>
              <span class="fs-16 text-align-justify">Kami membantu klien untuk menentukan tujuan serta fokus pembelajaran sesuai kurikulum dan program sekolah. Dilanjutkan dengan merancang program wisata edukasi yang bermanfaat untuk peserta didik, yang disesuaikan dengan kebutuhan program pembelajaran serta anggaran sekolah.</span>
          </div>
          <div class="d-flex-col">
            <div class="d-flex justify-center mb-2">
              <div class="border-point-red"></div>
              <h2 class="fs-30 ff-Custom-1">Pemandu Perjalanan</h2>
            </div>
            <div class="d-flex">
              <span class="text-align-justify fs-16">Kami membantu klien selama perjalanan dengan memberikan panduan dan pendampingan perjalanan pada aspek transportasi, akomodasi serta konsumsi, berikut dengan fasilitas penerjemah selama proses pembelajaran.</span>
            </div>
          </div>
          <div>
          <div class="d-flex justify-center">
            <div class="border-point-red"></div>
            <h2 class="fs-30 ff-Custom-1">Tujuan Perjalanan :</h2>
          </div>
          <div class="d-flex">
            <div class="grid-box-2 mt-1">
              <div class="d-flex-col">
                <span class="fs-16">•	Perusahaan & industri</span>
                <span class="fs-16">•	Sekolah & universitas</span>
                <span class="fs-16">•	Instansi pemerintah</span>
                <span class="fs-16">•	Yayasan sosial</span>
                <span class="fs-16">•	Museum</span>
              </div>
              <div class="d-flex-col">
                <span class="fs-16">•	Cagar alam & hutan kota</span>
                <span class="fs-16">•	Desa lokal</span>
                <span class="fs-16">•	Objek wisata</span>
                <span class="fs-16">•	Pusat perbelanjaan</span>
                <span class="fs-16">•	Tempat ibadah</span>
              </div>
            </div>
          </div>
        </div>
        </div>

        

        <div class="mt-11">
          <div class="d-flex justify-center mb-2">
            <div class="border-point-red"></div>
            <h2 class="fs-30 ff-Custom-1">Contoh Aktivitas Kunjungan</h2>
          </div>
          <div class="border br-5px pa-5 grid-box-2 ga-5" style="background-color: #F3F2EF;">
            <div class="d-flex">
              <div class="image-container">
                <img src="../assets/images/kansaiairport.png" alt="Kansai Airport" class="img-fluid small-img image-shadow">
              </div>
              <div class="pl-2 d-flex-col justify-center">
                <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Kansai Airport</h2>
                <span>Pembelajaran  sistem sanitasi air dan pembuatan lahan reklamasi</span>
              </div>
            </div>

            <div class="d-flex">
              <div class="image-container">
                <img src="../assets/images/maishima.png" alt="Maishima Inceneration Plant" class="img-fluid small-img image-shadow">
              </div>
              <div class="pl-2 d-flex-col justify-center">
                <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Maishima Inceneration Plant</h2>
                <span>Pembelajaran teknologi pengelolaan dan pemanfaatan energi  dari sampah</span>
              </div>
            </div>

            <div class="d-flex">
              <div class="image-container">
                <img src="../assets/images/LifeSafety.png" alt="Life Safety Center" class="img-fluid small-img image-shadow">
              </div>
              <div class="pl-2 d-flex-col justify-center">
                <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Life Safety Center</h2>
                <span>Kunjungan pembelajaran teknologi perakitan mobil </span>
              </div>
            </div>
            
            <div class="d-flex">
              <div class="image-container">
                <img src="../assets/images/shirakawa.png" alt="Shirakawa-go" class="img-fluid small-img image-shadow">
              </div>
              <div class="pl-2 d-flex-col justify-center">
                <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Shirakawa-go</h2>
                <span>Pembelajaran perkembangan ilmu pengetahuan sains dan alam semesta</span>
              </div>
            </div>

            <div class="d-flex">
              <div class="image-container">
                <img src="../assets/images/edowonderlant.png" alt="Edo Wonderland" class="img-fluid small-img image-shadow">
              </div>
              <div class="pl-2 d-flex-col justify-center">
                <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Edo Wonderland</h2>
                <span>Pembelajaran teknologi pembuatan jembatan bawah air dan pemanfaatan lahan reklamasi</span>
              </div>
            </div>

            <div class="d-flex">
              <div class="image-container">
                <img src="../assets/images/Amanohasidate.png" alt="Amanohashidate" class="img-fluid small-img image-shadow">
              </div>
              <div class="pl-2 d-flex-col justify-center">
                <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Amanohashidate</h2>
                <span>Pembelajaran perkembangan teknologi media massa di Jepang</span>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </section>

    <!-- Gallery -->
    <section id="gallery"  v-show="visibleSections.includes('gallery')" class="pt-16">
      <div class="container-dqa">
        <div class="d-flex-col pl-3 pr-3">
          <div class="align-self-center">
            <span class="fs-48 ff-Custom-1">Galeri</span>
          </div>
          <swiper
            :slidesPerView="3"
            :grid="{
              rows: 2,
            }"
            :spaceBetween="30"
            :pagination="{
              clickable: true,
            }"
            :modules="modules"
            :navigation="true"
            class="mySwiper hide-on-mobile"
          >
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0019.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0104.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0311.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0475.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3671.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0774.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0934.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0968.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_1246.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_1407.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3560.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3868.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3880.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4003.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4013.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4105.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4204.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_9952.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_9988.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_20230514_205108.jpg" />
            </swiper-slide>
    
          </swiper>

          <!-- Mobile -->
          <swiper
            :slidesPerView="2"
            :spaceBetween="30"
            :pagination="{
              clickable: true,
            }"
            :modules="modules"
            :navigation="true"
            class="mySwiper show-on-mobile"
          >
          <swiper-slide>
              <img src="../assets/images/galeri/IMG_0019.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0104.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3671.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0311.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0475.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0774.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0934.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0968.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_1246.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_1407.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3560.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3868.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3880.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4003.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4013.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4105.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4204.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_9952.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_9988.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_20230514_205108.jpg" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>

    <!-- Form -->
    <section id="form" v-show="visibleSections.includes('form')" class="pt-16 pb-11">
      <div class="container-dqa">
          <div class="text-align-center mb-2">
            <span class="fs-48 ff-Custom-1">Rencanakan Perjalanan</span>
          </div>
          <div class="d-flex justify-center pl-3 pr-3">
            <div class="form-container image-shadow">
              <form @submit.prevent="handleSubmit">
                <div class="form-row">
                  <div class="form-group">
                    <label for="namaInstansi">Nama Instansi: <span style="color: red">*</span></label>
                    <input type="text" v-model="form.namaInstansi" id="namaInstansi" required />
                  </div>
                  <div class="form-group">
                    <label for="emailInstansi">Email Instansi: <span style="color: red">*</span></label>
                    <input type="email" v-model="form.emailInstansi" id="emailInstansi" required />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="phone">Nomor Telepon: <span style="color: red">*</span></label>
                    <input type="tel" v-model="form.phone" id="phone" required />
                  </div>
                  <div class="form-group">
                    <label for="jumlahPeserta">Perkiraan Jumlah Peserta: <span style="color: red">*</span></label>
                    <input type="number" v-model="form.jumlahPeserta" id="jumlahPeserta" required />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="tanggalKeberangkatan">Perkiraan Tanggal Keberangkatan: <span style="color: red">*</span></label>
                    <input type="date" v-model="form.tanggalKeberangkatan" :min="today" id="tanggalKeberangkatan" required />
                  </div>
                  <div class="form-group">
                    <label for="tanggalKepulangan">Perkiraan Tanggal Kepulangan: <span style="color: red">*</span></label>
                    <input type="date" v-model="form.tanggalKepulangan"  :min="today" id="tanggalKepulangan" required />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="kotaTujuan">Kota/Tempat yang Ingin Dikunjungi: <span style="color: red">*</span></label>
                    <input type="text" v-model="form.kotaTujuan" id="kotaTujuan" required />
                  </div>
                  <div class="form-group">
                    <label for="akomodasi">Akomodasi yang Diinginkan: <span style="color: red">*</span></label>
                    <select v-model="form.akomodasi" id="akomodasi" required>
                      <option disabled value="">Pilih akomodasi</option>
                      <option>Hotel</option>
                      <option>Dorm</option>
                      <option>Apartment</option>
                      <option>Homestay</option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="service">Service: <span style="color: red">*</span></label>
                    <select v-model="form.service" id="service" required>
                      <option disabled value="">Pilih service</option>
                      <option>Pesawat + Visa + Program</option>
                      <option>Pesawat + Program</option>
                      <option>Program Saja</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="temaWisata">Tema / Fokus Wisata:</label>
                    <input type="text" v-model="form.temaWisata" id="temaWisata" />
                  </div>
                </div>
                <div class="form-group">
                  <label for="budget">Perkiraan Budget per Pax yang Dikehendaki:</label>
                  <div class="input-wrapper">
                    <span class="mr-2">Rp</span>
                    <input type="number" v-model="form.budget" id="budget" />
                  </div>
                </div>
                <div class="form-group">
                  <span class="fw-600 font-italic">Keterangan : Proses penghitungan biaya berlangsung selama 3-7 hari kerja.</span>
                </div>
                <div class="d-flex justify-end">
                  <button class="border pa-1 pl-3 pr-3 br-5px bg-success text--white" type="submit">Kirim</button>
                </div>
              </form>
            </div>
          </div>
      </div>
    </section>

    <!-- Footer -->
    <section id="contact" v-show="visibleSections.includes('contact')" style="background-color: #3c3c3c !important;">
      <section class="pa-5">
        <div class="container-dqa">
          <div class="d-flex footer justify-space-between">
            <div class="d-flex-col desc pt-2">
              <div>
                <span class="fs-24 fw-600 text--white">Travel with Amarta</span>
              </div>
              <div class="d-flex ga-2 pt-2">
                <span class="text--white text-align-justify ">
                  Travel With Amarta merupakan perusahaan travel yang menyediakan jasa perjalanan dengan fasilitas full service. Baik pemesanan tiket pesawat,  pembuatan visa Jepang, land operator dan asuransi perjalanan
                </span>
              </div>
            </div>
            <div class="hide-on-mobile pt-2">
              <ul class="d-flex ga-1">
                <li><a href="#about" class="fs-16 text--white cursor-pointer">Tentang Kami</a></li>
                <li><a href="#service" class="fs-16 text--white cursor-pointer">Layanan</a></li>
                <li><a href="#gallery" class="fs-16 text--white cursor-pointer">Galeri</a></li>
                <li><a href="#form" class="fs-16 text--white cursor-pointer">Formulir</a></li>
                <li><a href="#contact" class="fs-16 text--white cursor-pointer">Kontak</a></li>
              </ul>
            </div>
            <div class="d-flex-col pt-2">
              <div>
                <span class="fs-24 text--white ">Hubungi Kami</span>
              </div>
              <div class="pt-2">
                <a href="https://api.whatsapp.com/send/?phone=628818200436&text&type=phone_number&app_absent=0" target="_blank">
                  <div class="d-flex ga-2">
                    <i class="fa-brands fa-whatsapp text--white align-self-center"></i>
                    <span class="fs-16 text--white fw-400">08818200436</span>
                  </div>
                </a>
                <div class="d-flex ga-2 pt-2">
                  <i class="fa-regular fa-envelope text--white align-self-center"></i>
                  <span class="fs-16 text--white fw-400">twatravelwithamarta@gmail.com</span>
                </div>
                <a href="https://www.tiktok.com/@travelwithamarta" target="_blank">
                  <div class="d-flex ga-2 pt-2">
                    <i class="fa-brands fa-tiktok text--white align-self-center"></i>
                    <span class="fs-16 text--white fw-400">@travelwithamarta</span>
                  </div>
                </a>
                <a href="https://www.instagram.com/travelwithamarta/" target="_blank">
                  <div class="d-flex ga-2 pt-2">
                    <i class="fa-brands fa-instagram text--white align-self-center"></i>
                    <span class="fs-16 text--white fw-400">@travelwithamarta</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <div class="bg-white pa-1"></div> -->
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import {  Pagination,Navigation , Grid} from 'swiper/modules';

export default {
  name: 'HomePage',
  components: {
    Swiper,
    SwiperSlide,
  },
  data(){
    return{
      initialSlide: 4,
      form: {
          namaInstansi: '',
          emailInstansi: '',
          phone: '',
          jumlahPeserta: '',
          tanggalKeberangkatan: '',
          tanggalKepulangan: '',
          kotaTujuan: '',
          akomodasi: '',
          temaWisata: '',
          budget: '',
          service: ''
        },
      today: '',
      visibleSections: ['home']  
    }
  },
  setup() {
    return {
      modules: [ Pagination, Navigation, Grid],
    };
  },
  methods:{
    handleSubmit() {
      const formData = new FormData();
      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key]);
      });

      fetch('https://travelwithamarta.com/sendmail.php', {
        method: 'POST',
        body: formData,
      })
      .then(response => response.text())
      .then(data => {
        console.log(data);
        alert('Email sent successfully!');
        location.reload();
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Failed to send email.');
      });
    },
    setTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      this.today = `${year}-${month}-${day}`;
    },
    showSection(sectionId) {
      const sectionOrder = {
        'form': ['about', 'service', 'gallery', 'form'],
        'contact': ['about', 'service', 'gallery', 'form', 'contact'],
        'gallery': ['about', 'service', 'gallery'],
        'service': ['about', 'service'],
        'about': ['about']
      };

      const newSections = sectionOrder[sectionId] || [];
      
      this.visibleSections = [...new Set([...this.visibleSections, ...newSections])];

      this.$nextTick(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  },
  mounted(){
    this.setTodayDate();

    var navbar = this.$el.querySelector("#navbar");
    var navbarMobile = this.$el.querySelector("#navbar-mobile");
    var toggleBars = this.$el.querySelector("#toggleBar")

    window.addEventListener("scroll", function(){
      navbar.classList.toggle("sticky-navbar",window.scrollY > 0)
      navbarMobile.classList.toggle("sticky-navbar",window.scrollY > 0)
      
    })
   
    toggleBars.addEventListener("click", function(){
      navbarMobile.style.display =(navbarMobile.style.display === 'none' || navbarMobile.style.display === '') ? 'block' : 'none';
    })

    var navbarMobileLinks = navbarMobile.querySelectorAll('li');
    navbarMobileLinks.forEach(function (link) {
      link.addEventListener('click', function () {
    
        navbarMobile.style.display = 'none';
      });
    });

    const homeSection = document.getElementById('home');
    if (homeSection) {
      homeSection.scrollIntoView();
    }
  }
}

  

</script>